<template>
  <div class="basket">
    <div class="title">{{ $t("orderDetail.detail") }}</div>
    <div class="block">
      <div class="info_item">
        <span>{{ $t("orderDetail.content") }}</span>
        <span>{{ detail.sn }}</span>
      </div>
      <div class="info_item">
        <span>{{ $t("orderDetail.time") }}</span>
        <span>{{ detail.createTime }}</span>
      </div>
      <div class="info_item">
        <span>{{ $t("orderDetail.status") }}</span>
        <span>{{ detail.flagText }}</span>
      </div>
    </div>
    <div class="title">{{ $t("post.title") }}</div>
    <div class="block">
      <div class="info_item">
        <span>{{ $t("address.man") }}</span>
        <span>{{ detail.receiveMan }}</span>
      </div>
      <div class="info_item">
        <span>{{ $t("post.postMethod") }}</span>
        <span>{{ detail.expressCorpName }}-{{ detail.expressNo }}</span>
      </div>
      <div class="info_item">
        <span>{{ $t("tip.pmethod") }}</span>
        <span>{{ detail.paymentMethodText }}</span>
      </div>
      <div class="info_item">
        <span>{{ $t("address.address") }}</span>
        <span>{{ detail.receiveArea }}-{{ detail.receiveAddress }}</span>
      </div>
      <div class="info_item">
        <span>{{ $t("address.phone") }}</span>
        <span>{{ detail.receivePhone }}</span>
      </div>
    </div>
    <div class="title">{{ $t("orderDetail.content") }}</div>
    <div class="block">
      <div class="goods" v-for="val in detail.goods">
        <div class="img"><img class="regular" :src="val.cover" /></div>
        <div class="info">
          <div class="name">
            <span>{{ val.spuName }}</span>
          </div>
          <div class="sub" style="margin-bottom: 16px">{{ val.spuName }}</div>
          <div class="sub" v-for="v in val.specInfo.split(',')">{{ v }}</div>
          <div class="bottom">
            <div class="price">
              <span>€{{ val.amount }}</span>
              <!-- <span>€{{val.amount}}</span> -->
            </div>
            <div class="num">{{ $t("goodDetail.num") }}：{{ val.num }}</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="total">
        <span>{{ $t("tip.postFee") }}</span>
        <span>{{  detail.expressFee>0?'€'+detail.expressFee:$t("tip.free") }}</span>
      </div>
      <div class="total">
        <span>{{ $t("cart.total") }}</span>
        <span>€{{ detail.totalAmount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../api/request.js";
export default {
  name: "OrderDetail",
  components: {},
  data() {
    return {
      detail: {},
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$dialog.loading();
      Api.Order.detail({ orderId: this.$route.query.id }).then((res) => {
		  console.log(res)
        this.detail = res.data.data;
        this.$dialog.close();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  line-height: 65px;
}
.block {
  border: 1px solid #d9d9d9;
  padding: 40px;
  .line {
    border-top: 1px solid #d9d9d9;
  }
  .info_item {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    &:last-child {
      margin-bottom: 0;
    }
    & > span:first-child {
      display: block;
      width: 4em;
      margin-right: 40px;
    }
  }
}
.total {
  width: 800px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-top: 40px;
}
.goods {
  margin: 0 0 40px;
  width: 800px;
  display: flex;
  .img {
    width: 170px;
    height: 230px;
    background: #d8d8d8;
    margin-right: 20px;
  }
  .info {
    flex: 1;
    position: relative;
    .name {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 14px;
      & > span:last-child {
        font-weight: 400;
      }
    }
    .sub {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin-top: 11px;
    }
    .bottom {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      left: 0;
      right: 0;
      bottom: 0;
      .price {
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        line-height: 25px;
        // & > span:last-child {
        // 	font-size: 14px;
        // 	font-weight: 400;
        // 	line-height: 20px;
        // 	margin-left: 18px;
        // 	// text-decoration: line-through;
        // }
      }
      .num {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .title {
    font-size: 4vw;
    line-height: 12vw;
  }
  .block {
    padding: 3vw;
    .info_item {
      margin-bottom: 3vw;
      font-size: 3.2vw;
      line-height: 6vw;
      display: flex;
      justify-content: space-between;
      & > span:first-child {
        width: auto;
        margin-right: 0;
      }
    }
  }
  .total {
    width: 100%;
    line-height: 6vw;
    padding-top: 3vw;
  }
  .goods {
    width: 100%;
    .img {
      width: 40vw;
      height: 50vw;
      margin-right: 3vw;
    }
    .info {
      .name {
        display: block;
        & > span {
          display: block;
        }
        & > span:last-child {
          margin-top: 3vw;
        }
      }
      .bottom {
        .price {
          font-size: 3.5vw;
          & > span {
            display: block;
          }
          & > span:last-child {
            font-size: 3.2vw;
            font-weight: 400;
            line-height: 4vw;
            margin-left: 0;
            text-decoration: line-through;
          }
        }
      }
    }
  }
}
</style>
